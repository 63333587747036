import React from 'react';
import './Home.css';

class Home extends React.Component {
    render(){
        return (
            <div class="body">
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <div class="text">
                    <h1>Hi, I'm Doug! <em>Developer</em>, <em>coder</em>, whatever you wanna call it, it's <em>what I do</em>. 👾</h1>
                </div>        
            </div>
        )
    }
}

export default Home;